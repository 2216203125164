
This component displays two dropdowns for Lightrail Mode and Stop
which will be displayed on the top of the home screen

The selections cascade, so selecting Mode will get the stops

Once both have been selected, the submit button will be enabled


<v-btn
:disabled="allSelected == false" 
rounded
color="var(--primary-color)"
:dark="allSelected == true"
@click="this.navigateToTrips"
>
GET LIGHTRAIL ARRIVALS
</v-btn>


<template>
    <div :class="'form' + ($isTabletForm ? ' mobile' : '')">
        <span v-if="!noTitle" class="banner">Estimated Light Rail Arrivals</span>

        <DropDown 
            defaultValue="Select Line"
            :waiting="false"
            prefix=""
            :value="modeSelected.toUpperCase()"
            valueField="modeId"
            displayField="modeName"
            :items="modes"
            @onChange="setMode"
        />

        <DropDown 
            defaultValue="Select Station"
            :waiting="modeJustSelected"
            :value="stopSelected"
            :disabled="modeSelected === '-1'"
            valueField="bus_terminal_code"
            displayField="bus_terminal_name"
            :items="stops"
            @onChange="setStop"
        />

        <SimplifiedButton
            :disabled="allSelected == false" 
            :rounded="true"
            :dark="allSelected == true"
            @click-event="this.navigateToTrips"
            :buttonText="'GET LIGHTRAIL ARRIVALS'"
        >            
        </SimplifiedButton>


    </div>
</template>

<script>
    import { getLocations } from "@/requests/OptionRequests";
    import DropDown from "@/components/DropDown";

    import { setName } from "@/NameMappings";

    import SimplifiedButton from "@/components/SimplifiedButton";

    const BASE_STORAGE_KEY = "__-DVCascadeValues-__";
    const JOINED_STORAGE_KEY = "__-JOINED_SELECTION-__";

    export default {
        name: "LightRailCascadingSelections",
        props:["noTitle"],
        components: {
            DropDown,
            SimplifiedButton
        },
        data(){
            return {
                pageType: "lightrail",
                modes: [
                    {
                        modeId: "NLR",
                        modeName: "Newark Light Rail"
                    },
                    {
                        modeId: "HBLR",
                        modeName: "Hudson Bergen Light Rail"
                    },
                    {
                        modeId: "RL",
                        modeName: "Riverline"
                    }
                ],
                stops: [],
                modeSelected: "-1",
                stopSelected: "-1",
                allSelected: false,
                modeJustSelected: false
            }
        },
        created() {
            this.retrieveSelectionsFromStorage();
        },
        methods: {
            getStopList() {
                this.modeJustSelected = true;

                getLocations({
                    mode: this.modeSelected
                })
                .then(({data}) => {
                    console.log("STOPS", data);
                    this.stops = data;
                    this.modeStopSelected = "-1";

                    console.log("GET ALL STOPS FOR THIS ROUTE AND DIRECTION", data);

                    data.forEach(stop => {
                        setName(stop.bus_terminal_code, stop.bus_terminal_name, this.pageType + "-stop");
                    });

                    this.modeJustSelected = false;
                });
            },
            setMode(val) 
            {
                this.modeSelected = val;
                this.allSelected = false;
                this.stopSelected = "-1";
                this.getStopList();
            },
            setStop(val) {
                this.stopSelected = val;
                this.allSelected = true;
            },
            navigateToTrips() {
                this.saveSelectionsToStorage();
                this.$emit("navigate-to-trips", this.stopSelected, this.modeSelected);
            },
            saveSelectionsToStorage(){
                const str = JSON.stringify(this.$data);
                console.log("CASCADE SAVE", this.$data);
                localStorage.setItem(BASE_STORAGE_KEY + this.pageType, str);

                const strJ = JSON.stringify({
                    active_tab: 0,
                    isRoute: true,
                    isStop: false,
                    isTerminal: false
                });

                console.log("JOINED SAVE", strJ);

                localStorage.setItem(JOINED_STORAGE_KEY + this.pageType, strJ);
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);

                console.log("LIGHTRAIL CASCADE RETRIEVE", str);

                if(str){
                    const obj = JSON.parse(str);
                    if(obj){
                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            this[key] = obj[key];
                        });
                        return true;
                    }
                }
                return false;
            }
        }
    }
</script>

<style scoped>
    .form {
        display: flex;
        flex-direction: column;
        width: 45vw; /*500px;*/
        /* margin: 10px auto; */
        margin-top: 0px;
    }

    .form > *:not(:first-child) {
        margin-top: 10px;
    }


    .form.mobile {
        width: 500px;
        max-width: 90vw;
        margin-bottom: 0;
    }

    /* @media only screen and (max-width: 600px) {
        .form {
            width: 350px;
        }
    } */
</style>