
    <!-- <v-btn
    elevation="2"
    @click="gotoTrips"
    light
>
    <div style="display: flex; flex-direction: column;">
        <div v-for="(line, index) in nameBreaks" :key="index">
            {{line}}
        </div>
    </div>
</v-btn> -->

<template>
    <SimplifiedButton
        :light="true"
        @click-event="gotoTrips"
    >
        <div style="display: flex; flex-direction: column;">
            <div v-for="(line, index) in nameBreaks" :key="index">
                {{line}}
            </div>
        </div>
    </SimplifiedButton>
</template>

<script>
    import SimplifiedButton from '@/components/SimplifiedButton.vue';

    export default {
        name: "NearbyStopDisplay",
        props: ["stopName", "stopID", "index", "isHorizontal"],
        components: {
            SimplifiedButton
        },
        data() {
            return {
                nameBreaks: []
            }
        },
        mounted() {
            let val = this.$props.stopName.toLowerCase();
            // this.name = val.replace(" at ", " <br />at ");
            // this.name = val.replace("light", "").replace("rail", "").replace("station", "");
            val = val.replace("light", "").replace("rail", "").replace("station", "");

            console.log("ARE WE HORIZONTAL?", this.$props.isHorizontal);

            console.log("STOP DISPLAY VALUES", this.$props.stopName);

            // if(val.length > 16){
            if(this.$isTabletForm && val.length > 21){
                // find the space at the middlemost position
                let index = val.indexOf(" ");
                let distanceMid = val.length/2 - index;
                let more = true;

                console.log("BREAK POINT", val, index);

                while(more){
                    const pos = val.indexOf(" ", index+1);
                    const dist = Math.abs(val.length/2 - pos);
                    more = dist < distanceMid;
                    if(more){
                        index = pos;
                        distanceMid = dist;
                    }
                }

                this.nameBreaks.push(val.substr(0, index));
                this.nameBreaks.push(val.substr(index));
            }
            else{
                this.nameBreaks.push(val);
            }
        },
        methods: {
            gotoTrips(){
                console.log("MEMBER GOTO TRIPS", this.$props.index);
                // show the stops for this route, right?
                this.$emit("navigate-to-trips", this.$props.index);
            }
        }
    }
</script>

<style scoped>
    .mobile .theme--light.v-btn.v-btn--has-bg:not(.v-btn--disabled){
        width: 70vw;
        max-width: initial;
        padding: 0.2rem 0;
    }

    .mobile .button {
        width: 70vw;
        max-width: initial;
        padding: 0.2rem 0;
    }
</style>