
Just show a button which, when clicked, navigates to the /map route

<template>
    <div v-if="usingGeolocation" :class="'map-button' + ($isTabletForm ? ' mobile' : '')" @click="navigateToMap">
        <button>
        </button>
        <div class="map-button-label" v-if="withLabel !== false">Map View</div>
    </div>
</template>

<script>
    import { onlyNonNullValues } from "@/Utilities";

    export default {
        name: "MapButton",
        props: ["query", "usingGeolocation", "withLabel"],
        methods: {
            navigateToMap(evt) {
                evt.preventDefault();
                
                const nonTrip = {...this.$props.query};
                nonTrip.tripid = null;

                this.$router.push({
                    path: 'map',
                    query: onlyNonNullValues(nonTrip) 
                });
            }
        }
    }
</script>

<style scoped>
    .map-button {
        display: flex;
        justify-content: center;
        align-items: center;
    } 

    .mobile .map-button {
        /* margin-top: 1vh; */
    }

    button {
        height: calc(7 * var(--mvh));
        width: calc(7 * var(--mvh));
        background-size: 100%;
        background-image: url("~@/assets/map_icon.png");
    }

    .mobile button {
        padding: 4vw;
        font-size: 1.1rem;
    }

    .map-button-label {
        font-size: 1.5rem;
        margin-left: 1rem;
        cursor: pointer;
    }

</style>