
Title element for the top of the home page display

The text will change based on which transportation type the user is currently within

<template>
    <div v-if="!condensed" :class="'title-container' + ($isTabletForm ? ' mobile' : '') + (singleLineOnly ? ' single' : '')">
        <div :class="'inner-container' + (landing ? ' landing' : '')">
            <div class="njt">
                <div class="njt-icon" @click="redirectToLanding">
                </div>
                <!-- <h3 class="subtitle">
                    New Jersey Transit
                </h3> -->
            </div>
            <h1 v-if="!singleLineOnly" :class="'title' + (className ? ' ' + className : '')">
                {{title}} Departure Vision
            </h1>
            <div v-if="$isTabletForm && !landing" class="inner-after"></div>
        </div>
    </div>
    <div v-else class="condensed">
        <div class="njt njt-icon" @click="redirectToLanding"></div>

        <div class="location-button">
            <v-btn
                v-if="(isMapPage && lineDisplay)"
                elevation="2"
                @click="this.navigateToStops"
            >
                HOME
            </v-btn>
            <v-btn
                v-else-if="isMapPage || ((isTripsPage || isStationPage) && !fromMap)"
                elevation="2"
                @click="this.navigateHome"
            >
                {{(query.filtered || isStationPage || isMapPage) ? 'HOME' : 'BACK'}}
            </v-btn>
            <v-btn
                v-else-if="(isMapPage || isTripsPage) && fromMap"
                elevation="2"
                @click="this.navigateToMap"
            >
                {{query.filtered ? 'MAP' : 'BACK'}}
            </v-btn>
        </div>

        <h1 v-if="replacement" :class="'title replacement' + (className ? ' ' + className : '') + (query.filtered ? ' back-button' : '')"
            :style="`cursor: pointer; ${(replacement.length > 25) ? ((replacement.length > 65) ? 'font-size: 1rem;' : 'font-size: 1.3rem;') : ''}`"
            @click="navigateBack()"
        >
            {{replacement}}
        </h1>
        <h1 v-else-if="!hideText" :class="'title' + (className ? ' ' + className : '')">
            {{title}} Departure Vision
        </h1>
        <h1 v-else :class="'title replacement' + (className ? ' ' + className : '')">

        </h1>
    </div>
</template>

<script>

    import { onlyNonNullValues } from "@/Utilities";

    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";

    export default {
        name: "NJT_Title",
        props: ["condensed", "replacementText", "hideText", "landing", "singleLineOnly", "query", "isTripsPage", "isMapPage", "fromMap", "isStationPage", "lineDisplay"],
        data: () => ({
            title: "",
            className: "bus",
            replacement: ""
        }),
        watch: { 
            replacementText: function(newVal, oldVal) { // watch it
                console.log('replacementText PROP CHANGED: ', newVal, ' | was: ', oldVal);
                this.nameCheck();
            }
        },
        mounted() {
            const path = this.$route.path.split("/")[1];
            this.className = path;

            console.log("TITLE PATH CHECK", path);

            // if(this.$props.replacementText){
            //     if(this.$props.replacementText !== "Station to Station"){
            //         this.replacement = this.$props.replacementText.replace(" Station", "").replace(" at Centre St", "");
            //     }
            //     else{
            //         this.replacement = this.$props.replacementText;
            //     }
            // }

            this.nameCheck();

            console.log("PROPS CHECK", this.$props);

            if(path === "bus"){
                this.title = "Bus";
            }

            if(path === "lightrail"){
                this.title = "Light Rail";
            }

            if(path === "rail"){
                this.title = "Rail";
            }
        }, 
        methods: {
            nameCheck() {
                if(this.$props.replacementText){
                    if(this.$props.replacementText !== "Station to Station"){
                        this.replacement = this.$props.replacementText.replace(" Station", "").replace(" at Centre St", "");
                    }
                    else{
                        this.replacement = this.$props.replacementText;
                    }
                }
            },
            redirectToLanding() {
                // need to remove values from local storage?
                localStorage.removeItem(__SELECTED_DEFAULT_PAGE_KEY__);

                this.$router.push({
                    path: `/`
                });
            },
            navigateBack(){
                console.log("DO WE LINK BACK?", this.$props.isTripsPage, this.$props.query);
                if(this.$props.isTripsPage && this.$props.query.route) {
                    console.log("LINK BACK TO MAIN TRIPS PAGE");
                    
                    const queryCopy = {...this.$props.query};
                    queryCopy.route = null;
                    queryCopy.filtered = null;

                    this.$router.push({
                        path: 'trips',
                        query: onlyNonNullValues(queryCopy) 
                    });
                }
            },
            navigateToStops() {
                const queryCopy = {...this.$props.query};
                queryCopy.lineDisplay = null;

                this.$router.push({
                    path: 'stops',
                    query: onlyNonNullValues(queryCopy) 
                });
            },
            navigateHome() {
                this.$router.push({
                    path: '/'
                });
            },
            navigateToMap() {
                this.$router.push({
                    path: `map`
                });
            }
        }
    }
</script>

<style scoped>

    .title-container {
        height: calc(15 * var(--mvh));
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(3 * var(--mvh));
        left: 30vw;
        transform: scale(1.1) translate(-50%);
    }

    .mobile.title-container {
        height: initial;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin-bottom: calc(6 * var(--mvh));
    }

    .mobile.single.title-container {
        margin-bottom: calc(1.5 * var(--mvh));
    }

    .landing .mobile.title-container {
        margin-bottom: 0;
    }


    .inner-container {
        display: flex; 
        flex-direction: column;
        align-items: center; 
        justify-content: center; 
        height: 100%;
        padding-left: 3rem;
    }

    .mobile .inner-container {
        padding-left: 0;
        width: 100vw;
    }

    .mobile .inner-container:not(.landing)::before {
        content: "";
        position: absolute;
        top: calc(2 * var(--mvh) + 1rem);
        left: 1vw;
        width: 23vw;
        height: 2px;
        background-color: var(--primary-color);
        /* z-index:-10; */
        box-shadow: 0 8px 0 var(--primary-color), 0 -8px 0 var(--primary-color);
    }

    .mobile .inner-container:not(.landing)::after {
        content: "";
        position: absolute;
        top: calc(2 * var(--mvh) + 1rem);
        right: 1vw;
        width: 23vw;
        height: 2px;
        background-color: var(--primary-color);
        /* z-index:-10; */
        box-shadow: 0 8px 0 var(--primary-color), 0 -8px 0 var(--primary-color);
    }

    .inner-after {
        position: absolute;
        bottom: -0.5rem;
        left: 5vw;
        width: 90vw;
        height: 2px;
        background-color: var(--primary-color);
    }

    /* .mobile.single .inner-container:not(.landing)::after {
        content: none;
    } */

    .landing .title-container {
        height: initial;
        position: relative;
        top: 0;
        left: 0;
        transform: scale(1.1);
        margin-top: 1rem;
    }

    .mobile .landing .title-container {
        margin-top: none;
    }

    .landing .inner-container {
        padding-left: 0;
        /* padding-top: 3rem; */
    }

    .mobile .landing .inner-container {
        padding-top: 0;
    }

    .njt {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        height: 100%;
        /* margin-top: 4.5vh; */
    }

    .condensed {
        display: flex; 
        align-items: center; 
        justify-content: flex-start; 
        height: 100%;
    }

    .subtitle {
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
    }

    .njt-icon {
        position: relative;
        content: "";
        background: url("../assets/NJT_HORIZOINTAL_LOGO.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 20rem;
        height: 3.9rem;
        margin-right: .5vw;
        cursor: pointer;
        transform: translateY(.14rem) scale(.7);
    }

    .dark .condensed .njt-icon {
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .mobile .njt-icon {
        margin-right: 0;
        transform: translateY(-0.35rem) scale(0.55);
    }

    .condensed .njt-icon {
        width: 3.6rem;
        height: 4.5rem;
        margin-left: 1.2rem;
        margin-right: 2.5rem;
        position: relative;
    }

    .title {
        font-weight: normal;
        text-align: center;
        position: relative;
    }

    .title {
        font-size: 3.5rem;
    }

    .title.bus {
        font-size: 3.5rem;
    }

    .mobile .title.bus {
        margin-top: calc(1 * var(--mvh));
    }

    .condensed .title.bus {
        font-size: 2.5rem;
    }

    .condensed .replacement.title.bus {
        height: 70%;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title.lightrail {
        font-size: 2.8rem;
        margin-top: calc(1 * var(--mvh));
    }

    .condensed .title.lightrail {
        font-size: 2.2rem;
    }

    .condensed .replacement.title.lightrail {
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title.rail {
        font-size: 3.6rem;
        margin-top: calc(1 * var(--mvh));
    }

    .condensed .title.rail {
        font-size: 2.6rem;
    }

    .condensed .replacement.title.rail {
        height: 70%;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .condensed .title {
        margin-top: 0;
    }

    .condensed .njt-icon::after,
    .condensed .njt-icon::after,
    .condensed .njt-icon::after
    {
        content: "";
        position: absolute;
        right: -1.3rem;
        width: 0.2rem;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
    }

    .location-button .v-btn {
        max-width: 60vw !important;
        z-index: 1;
        margin-right: 1rem;
    }

    .back-button::before {
        left: calc(-1.5rem - 2px) !important;
    }

    .dark .condensed .title.bus::before,
    .dark .condensed .title.title.rail::before,
    .dark .condensed .title.title.lightrail::before {
        background-color: white;
    }

    .condensed .title.bus::before {
        height: 2.5rem;
    }

    .condensed .title.lightrail::before {
        top: 0;
        height: 2.5rem;
    }

    .condensed .title.rail::before {
        height: 2.5rem;
    }

    .landscape .title::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -1.5rem;
        height: 80%;
        width: 0.5rem;
        transform: translateY(-50%);
        background-color: var(--primary-color);
    }

    .back-button {
        border: 2px solid var(--primary-color);
        padding: 0.5rem 1rem;
        box-shadow: 0.25rem 0.25rem 0.5rem rgb(0 0 0 / 30%);
        border-radius: 2rem;
        transition: 0.3s;
    }

    .dark .back-button {
        border: 2px solid white;
    }

    .back-button:hover {
        background-color: #eee;
    }

    .dark .back-button:hover {
        color: var(--primary-color);
    }

    .mobile .njt {
        margin-top: calc(1.3 * var(--mvh));
        margin-bottom: calc(0.5 * var(--mvh));
        background: white;
        padding: 0 1rem;
    }

    .mobile .subtitle {
        font-size: 1.5rem;
    }

    .smallscreen .title {
        font-size: 3.5rem;
    }

    .mobile .title {
        font-size: 2.5rem;
        margin-bottom: calc(2 * var(--mvh));
    }

    .tablet .title {
        font-size: 3.5rem;
    }

    .mobile .landing .title {
        margin-bottom: calc(2 * var(--mvh));
    }

</style>