<template>
    <div class="chat-button-container">
        <div class="chat-button">
            <div class="chat-balloon">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <div class="chatbot-corner"></div>
            <div class="antenna antenna1">
                <div id="beam"></div>
                <div id="beam-pulsar"></div>
            </div>
            <div class="antenna antenna2">
                <div id="beam"></div>
                <div id="beam-pulsar"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ChatButton"
    };
</script>

<style scoped>

    .chat-button-container {
        position: absolute;
        transform: scale(0.4) translateX(0);
        cursor: pointer;
        /* left:  -30px; */
        /* top:  -34px;
        width: 150px;
        height: 85px; */
        --duration: 15s;
    }

    .chat-button {
        position: absolute;
        animation: up-down var(--duration) infinite ease-in-out;
    }  

    .tablet .chat-button {
        animation: tablet-up-down var(--duration) infinite ease-in-out;
    }

    .chat-balloon {
        margin: auto;
        position: absolute;
        width: 150px;
        height: 85px;
        border: 4px solid black;
        border-radius: 5rem;
        background-color: white;
    }

    .chatbot-corner {
        margin: auto;
        position: absolute;
        /* bottom: 0;
        right: 0; */
        top: 79px;
        /* top: 107px; */
        left: 25px;
        width: 0;
        height: 0;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        border-top: 17px solid black;
        transform: rotate(145deg);
    }

    .antenna1 {
        transform: translate(41px, 4px);
    }

    .antenna2 {
        transform: translate(96px, 4px);
    }

    .antenna {
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: -40px;
        height: 20px;
        width: 10px;
        background-color: black;
        animation: antenna-appear var(--duration) infinite ease-in-out;
    }
    
    #beam {
        position: absolute;
        top: -12.5px;
        left: -5px;
        height: 25px;
        width: 18px;
        border-radius: 50%;
        background-color: black;
        animation: beam-appear var(--duration) infinite ease-in-out;
    }
    
    #beam-pulsar {
        position: absolute;
        top: -12.5px;
        left: -5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: black;
        animation: beam-pulsar-appear var(--duration) infinite ease-in-out;
    }


    .dot {
        margin: auto;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        height: 17.5px;
        width: 17.5px;
        left: -65px;
        background-color: black;
        border-radius: 50%;
    }

    .dot:nth-child(2) {
        left: 0;
        animation: pulse-inner var(--duration) infinite ease-in-out;
        animation-delay: 0.2s;
    }

    .dot:nth-child(1) {
        animation: wink var(--duration) infinite ease-in-out;
    }

    .dot:nth-child(3) {
        left: 65px;
        animation: eye-lift var(--duration) infinite ease-in-out;
    }

    @keyframes wink {
        0% { transform: translateY(-0); }
        50% { transform: translateY(-0); }
        72.5% { transform: translateY(-0); }
        75% { transform: translateY(-7px); }
        77.5% { transform: translateY(-7px); height: 17.5px; }
        80% { transform: translateY(-7px); height: 2px; }
        82.5% { transform: translateY(-7px); height: 17.5px; }
        97.5% { transform: translateY(-7px); }
        100% { transform: translateY(0); height: 17.5px; }
    }

    @keyframes eye-lift {
        0% {
            transform: translateY(-0);
        }
        50% {
            transform: translateY(-0);
        }
        72.5% {
            transform: translateY(-0);
        }
        75% {
            transform: translateY(-7px);
        }
        97.5% {
            transform: translateY(-7px);
        }
        100% {
            transform: translateY(-0);
        }
    }

    @keyframes pulse-inner {
        0% { transform: scale(1); }
        65% { transform: scale(1); }
        68.5% { transform: scale(1); }
        72.5% {
            top: 0;
            transform: scale(1); 
            height: 17.5px; 
            width: 17.5px;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            transform: rotate(-370deg);
        }
        75% {
            top: 35px;
            height: 15px;
            width: 22px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 3rem;
            border-bottom-right-radius: 3rem;
            transform: rotate(10deg);
        }
        77.5% { transform: rotate(-10deg); }
        80% { transform: rotate(10deg); }
        82.5% { transform: rotate(-10deg); }
        82.5% { transform: rotate(0deg); }
        92.5% {
            top: 35px;
            height: 15px;
            width: 22px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 3rem;
            border-bottom-right-radius: 3rem;
            transform: rotate(0deg); 
        }
        95% {
            top: 35px;
            height: 15px;
            width: 22px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 2.5rem;
            border-bottom-right-radius: 2.5rem;
            transform: rotate(0deg); 
        }
        100% {
            top: 0;
            height: 17.5px;
            width: 17.5px;
            border-radius: 50%;
            transform: rotate(-360deg);
        }
    }

    @keyframes pulse-outer {
        0% { transform: scale(1); }
        50% { transform: scale(1); }
        53.5% { transform: scale(1.1); }
        57.5% { transform: scale(1); }
        61% { transform: scale(1.1); }
        65% { transform: scale(1); }
        69% { transform: scale(1.1); }
        72.5% { transform: scale(1); height: 17.5px; }
        77.5% { transform: scale(1); height: 5px; }
        80% { height: 17.5px; }
        87.5% { height: 17.5px; }
        90% { transform: scale(1); height: 5px; }
        92.5% { height: 17.5px; }
        100% { height: 17.5px; }
    }

    @keyframes antenna-appear {
        0% { visibility: hidden; top: -20px; height: 0 }
        50% { visibility: hidden; top: -20px; height: 0 }
        75% { visibility: hidden; top: -20px; height: 0 }
        77.5% { visibility: visible; top: -45px; height: 20px; }
        97.5% { visibility: visible; top: -45px; height: 20px; }
        100% { top: -40px; height: 0; }
    }

    @keyframes beam-appear {
        0% { visibility: hidden; top: -15px; height: 0 }
        50% { visibility: hidden; top: -15px; height: 0 }
        75% { visibility: hidden; top: -15px; height: 0 }
        77.5% { visibility: visible; top: -15px; height: 20px; width: 20px; }
        100% { visibility: visible; top: -15px; height: 20px; width: 20px; }
    }

    @keyframes beam-pulsar-appear {
        0% { visibility: hidden; top: -15px; height: 0 }
        50% { visibility: hidden; top: -15px; height: 0 }
        75% { visibility: hidden; top: -15px; height: 0 }
        77.5% { visibility: visible; top: -15px; left: -5px; height: 20px; width: 20px; opacity: 1 }
        82.5% { top: -27.5px; left: -15px; height: 40px; width: 40px; opacity: 0; visibility: visible; }
        87% { visibility: hidden; opacity: 0; }
        87.5% { visibility: visible; top: -15px; left: -5px; height: 20px; width: 20px; opacity: 1 }
        92.5% { top: -27.5px; left: -15px; height: 40px; width: 40px; opacity: 0; visibility: visible; }
        97% { visibility: hidden; opacity: 0; }
        100% { visibility: hidden; opacity: 0; }
    }

    @keyframes up-down {
        0% { transform: translate(0); }
        50% { transform: translate(0); }
        56% { transform: translate(0, 0.5%); }
        62.5% { transform: translate(0);  }
        69% { transform: translate(0, 0.5%); }
        75% { transform: translate(0); }
        81% { transform: translate(0, 0.5%); }
        87.5% { transform: translate(0); }
        94% { transform: translate(0, 0.5%); }
        100% { transform: translate(0); }
    }

    @keyframes tablet-up-down {
        0% { transform: scale(1.3) translate(0); }
        50% { transform: scale(1.3) translate(0); }
        56% { transform: scale(1.3) translate(0, 0.5%); }
        62.5% { transform: scale(1.3) translate(0);  }
        69% { transform: scale(1.3) translate(0, 0.5%); }
        75% { transform: scale(1.3) translate(0); }
        81% { transform: scale(1.3) translate(0, 0.5%); }
        87.5% { transform: scale(1.3) translate(0); }
        94% { transform: scale(1.3) translate(0, 0.5%); }
        100% { transform: scale(1.3) translate(0); }
    }

</style>