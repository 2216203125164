
This component displays the three dropdowns for Route, Direction, and Stop
which will be displayed on the top of the home screen

The selections cascade, so selecting route will get the available values for direction
and selecting direction will get the available values for stop

Once all three have been selected, the submit button will be enabled

<v-btn
:disabled="allSelected == false" 
rounded
color="var(--primary-color)"
:dark="allSelected == true"
@click="this.navigateToTrips"
>
GET {{pageType}} {{ (pageType.toLowerCase() !== "lightrail") ? "DEPARTURES" : "ARRIVALS" }}
</v-btn>

<template>
    <div :class="'form' + ($isTabletForm ? ' mobile' : '')">
        <span v-if="!noTitle" class="banner">Estimated {{pageType}} Arrivals</span>

        <DropDown 
            key="lastSwitch"
            defaultValue="Select Route"
            :waiting="routes.length === 0"
            prefix="Route: "
            :value="routeSelected.toUpperCase()"
            valueField="BusRouteID"
            :displayField="(pageType === 'bus') ? 'BusRouteID' : 'BusRouteDescription'"
            :items="routes"
            @onChange="setRoute"
        />

        <DropDown 
            key="lastSwitch"
            defaultValue="Select Direction"
            :waiting="routeJustSelected"
            prefix="Direction: "
            :value="directionSelected.toUpperCase ? directionSelected.toUpperCase() : directionSelected"
            :disabled="routeSelected === '-1'"
            valueField=""
            displayField=""
            :items="directions"
            :shortList="true"
            @onChange="setDirection"
        />

        <DropDown 
            key="lastSwitch"
            defaultValue="Select Stop"
            :waiting="directionJustSelected"
            :value="routeStopSelected"
            :disabled="directionSelected === '-1'"
            valueField="busstopnumber"
            displayField="busstopdescription"
            :items="routeStops"
            @onChange="setStop"
        />

        <SimplifiedButton
            :disabled="allSelected == false" 
            :rounded="true"
            :dark="allSelected == true"
            @click-event="this.navigateToTrips"
            :buttonText="buttonText"
        >
        </SimplifiedButton>
    </div>
</template>

<script>
    import { getAllRoutes, getDirections, getStopsForRoute } from "@/requests/OptionRequests";
    import DropDown from "@/components/DropDown";

    import SimplifiedButton from "@/components/SimplifiedButton";

    import { setName } from "@/NameMappings";

    import InitialLocations from "@/InitialLocations";

    const BASE_STORAGE_KEY = "__-DVCascadeValues-__";
    const JOINED_STORAGE_KEY = "__-JOINED_SELECTION-__";

    export default {
        name: "BusCascadingSelections",
        props:["noTitle"],
        components: {
            DropDown,
            SimplifiedButton
        },
        data(){
            return {
                lastSwitch: null,
                routes: InitialLocations["busRoutes"],
                routeStops: [],
                directions: [],
                routeSelected: "-1",
                routeStopSelected: "-1",
                directionSelected: "-1",
                allSelected: false,
                pageType: "bus",
                routeJustSelected: false,
                directionJustSelected: false,
                buttonText: ""
            }
        },
        watch: {
            routes() {
                this.lastSwitch = new Date();
            },
            routeStops() {
                this.lastSwitch = new Date();
            }
        },
        created() {
            this.getRoutes();
            this.setButtonText();
        },
        methods: {
            getRoutes() {
                // on creation, get the mode from the URL and then get the routes
                // to fill the first dropdown

                this.pageType = this.$route.path.split("/")[1];

                this.setButtonText();

                const params = {
                    mode: null
                };

                // "/bus", "/lightrail", "/riverline"
                switch(this.pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                }

                let indicators = document.querySelectorAll(".message-square");
                if(indicators){
                    for(let i = 0; i < indicators.length; i++) {
                        indicators[i].remove();
                    }
                }
                indicators = null;

                setTimeout(() => {
                    console.log("CHECK CASCADING AGAIN!!!!!!!");
                    if(!this.routes || this.routes.length === 0){
                        console.log("RUNNING CASCADING SELECTION AGAIN!!!!!!");
                        this.getRoutes();
                    }
                }, 5000);

                getAllRoutes(params)
                .then(({data}) => {
                    console.log("GET ROUTE DATA YYY", data, JSON.stringify(data));

                    this.routes = data;

                    // this.routes.forEach(loc => {
                    //     setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                    // });

                    // this.routes.forEach(loc => {
                    //     setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                    // });

                    this.retrieveSelectionsFromStorage();

                    // if I just force an update, will it re-render this and the child dropdown?
                    // does this disrupt anything?
                    this.$forceUpdate();

                    setTimeout(() => {
                        // create an html element and add it to the window as an indicator
                        const indicate = document.createElement("div");
                        
                        indicate.classList.add("message-square");
                        if(!this.$isTroubleshootingMode){
                            indicate.classList.add("message-hidden");
                        }

                        indicate.style.height = "80px";
                        indicate.style.width = "80px";
                        indicate.style.backgroundColor = "blue";
                        indicate.style.zIndex = "10000";
                        indicate.style.position = "absolute";
                        indicate.style.top = 0;
                        indicate.style.left = "200px";

                        indicate.style.color = "white";

                        let str = "";
                        if(data){
                            str = "data exists: ";
                            if(data.length){
                                str += " " + data.length;
                            }
                        }
                        else{
                            str = "null data";
                        }

                        indicate.textContent = str;

                        document.body.appendChild(indicate);
                    }, 10);
                })
                .catch(err => () => {
                    console.log("CASCADING SELECTION GET LOCATION ERROR", err);

                    
                    const indicate = document.createElement("div");
                    
                    indicate.classList.add("message-square");
                    if(!this.$isTroubleshootingMode){
                        indicate.classList.add("message-hidden");
                    }

                    indicate.style.height = "80px";
                    indicate.style.width = "80px";
                    indicate.style.backgroundColor = "orange";
                    indicate.style.zIndex = "10000";
                    indicate.style.position = "absolute";
                    indicate.style.top = "80px";
                    indicate.style.left = 0;

                    indicate.textContent = err.message;

                    document.body.appendChild(indicate);
                });
            },
            getDirectionList() {
                this.routeJustSelected = true;
                getDirections(this.routeSelected)
                .then(({data}) => {
                    console.log("DIRECTIONS", data);
                    this.directions = [data[0].Direction_1, data[0].Direction_2];
                    this.directionSelected = "-1";
                    this.routeStops = [];
                    this.routeStopSelected = "-1";
                    this.routeJustSelected = false;
                });
            },
            getStopList() {
                this.directionJustSelected = true;
                getStopsForRoute({
                    route: this.routeSelected,
                    direction: this.directionSelected
                })
                .then(({data}) => {
                    console.log("STOPS", data);
                    this.routeStops = data;
                    this.routeStopSelected = "-1";

                    console.log("GET ALL STOPS FOR THIS ROUTE AND DIRECTION", data);

                    data.forEach(stop => {
                        setName(stop.busstopnumber, stop.busstopdescription, this.pageType + "-stop");
                    });

                    this.directionJustSelected = false;
                });
            },
            setRoute(val) 
            {
                this.routeSelected = val;
                this.allSelected = false;
                this.directionSelected = -1;
                this.routeStopSelected = -1;
                this.getDirectionList();
            },
            setDirection(val) {
                this.directionSelected = val;
                this.routeStopSelected = -1;
                this.allSelected = false;
                this.getStopList();
            },
            setStop(val) {
                this.routeStopSelected = val;
                this.allSelected = true;
            },
            navigateToTrips() {
                this.saveSelectionsToStorage();
                this.$emit("navigate-to-trips", this.routeStopSelected, this.routeSelected, this.directionSelected);
            },
            saveSelectionsToStorage(){
                const str = JSON.stringify(this.$data);
                console.log("CASCADE SAVE", this.$data);
                localStorage.setItem(BASE_STORAGE_KEY + this.pageType, str);

                const strJ = JSON.stringify({
                    active_tab: 0,
                    isRoute: true,
                    isStop: false,
                    isTerminal: false
                });

                console.log("JOINED SAVE", strJ);

                localStorage.setItem(JOINED_STORAGE_KEY + this.pageType, strJ);
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);
                if(str){
                    const obj = JSON.parse(str);
                    if(obj){
                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            this[key] = obj[key];
                        });
                        return true;
                    }
                }
                return false;
            },
            setButtonText() {
                this.buttonText = `GET ${this.pageType} ${ (this.pageType.toLowerCase() !== "lightrail") ? "DEPARTURES" : "ARRIVALS" }`;
            }
        }
    }
</script>

<style scoped>
    .form {
        display: flex;
        flex-direction: column;
        width: 45vw; /*500px;*/
        /* margin: 10px auto; */
        margin-top: 0px;
    }

    .form > *:not(:first-child) {
        margin-top: 10px;
    }


    .form.mobile {
        max-width: 90vw;
        width: 500px;
        margin-bottom: 0;
    }

    /* @media only screen and (max-width: 600px) {
        .form {
            width: 350px;
        }
    } */
</style>