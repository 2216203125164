
Container element for laying out the nearby routes and stops, 
as well as the MapButton (if it's set to be shown based on props)

<!-- 
    <v-btn 
v-if="getOwnData && pageType === 'rail'"
rounded
color="var(--primary-color)"
dark
@click="startReporting">
Report an Issue
</v-btn> 

<v-btn 
    rounded
    color="var(--primary-color)"
    dark
    @click="closeModal">
    Back to Home
</v-btn>
-->


<template>
    <div v-if="usingGeolocation" :class="'wrapper nearby-container' + ($isTabletForm ? ' mobile' : ' landscape')  + ((isHorizontal && !$isTabletForm) ? ' horizontal' : '')" >
        <div :class="'nearby-inner '">
            <div v-if="!isHorizontal" style="width: 100%; display: flex; gap: 2rem; align-items: center; justify-content: center;">
                <div class="nearby-message">Close to you right now</div>
            </div>
            <NearbyRoutes :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-stops="navigateToStops" />
            <NearbyStops :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-trips="navigateToTrips" />
            <SimplifiedButton
                v-if="getOwnData && pageType === 'rail'"
                :rounded="true"
                :dark="true"
                :buttonClass="$isMobile ? 'reporting-shift': ''"
                @click-event="startReporting"
            >
                Report an Issue
            </SimplifiedButton>
        </div>
        <div v-if="isReportMode" class="report-modal">
            <!-- <div v-if="$isSmallScreen" class="mobile-cover"></div> -->
            <div v-if="reportPage === 1" class="modal-flex">
                <h3>
                    Please Select the Train or Station You Would Like to Report
                </h3>
                <NearbyRoutes :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-stops="navigateToStops" :isReporting="true" />
                <NearbyStops :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-trips="navigateToTrips" :isReporting="true" />
            </div>
            <div v-else-if="reportPage === 2" class="modal-flex">
                <h3>
                    Please Select the Type of Issue You Would Like to Report
                </h3>
                <div class="report-modal-second-layout">
                    <div class="modal-flex">
                        <div v-if="reportSelectedType === 'train'" class="modal-route-display">
                            <NearbyRouteDisplay 
                                :routeName="reportSelectedRoute"
                                :vehicleID="reportSelectedID" 
                            />
                        </div>
                        <div v-else class="v-btn" style="background-color: #f2f2f2; opacity: 0.9;">
                            {{ reportSelectedName }}
                        </div>
                    </div>
                    <div class="report-modal-issue-list">
                        <button @click="() => submitReport('police')" class="modal-button police-button">
                            <div class="report-icon police-icon"></div>
                            &nbsp;
                            Police Activity
                        </button>
                        <button @click="() => submitReport('crowd')" class="modal-button crowd-button">
                            <div class="report-icon crowd-icon"></div>
                            &nbsp;
                            Overcrowding
                        </button>
                        <button @click="() => submitReport('delay')" class="modal-button delay-button">
                            <div class="report-icon delay-icon"></div>
                            &nbsp;
                            Major Delay
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <h3 style="margin-bottom: 2rem;">
                    Thank You for Your Feedback!
                </h3>
                <SimplifiedButton
                    :rounded="true"
                    :dark="true"
                    @click-event="closeModal"
                >
                    Back to Home
                </SimplifiedButton>
            </div>            
            <!-- <button v-if="reportPage === 2" @click="reportGoBack" class="report-back-button"> back </button> -->
            <button v-if="reportPage === 2" @click="reportGoBack" class="report-back-button-icon"></button>
            <button @click="closeModal" class="modal-cancel">X</button>
            <div class="page-counter">
                <div :class="'page-circle ' + (reportPage === 1 ? 'circle-active' : '')"></div>
                <div :class="'page-circle ' + (reportPage === 2 ? 'circle-active' : '')"></div>
                <div :class="'page-circle ' + (reportPage === 3 ? 'circle-active' : '')"></div>
            </div>
        </div>
    </div>
    <div v-else class="wrapper geo-disabled">
        Please enable geolocation to see nearby buses and routes
    </div>
</template>

<script>
    import NearbyRoutes from "@/data_display/NearbyRoutes";
    import NearbyStops from "@/data_display/NearbyStops";
    // import MapButton from "@/components/MapButton";

    import NearbyRouteDisplay from "@/data_display/NearbyRouteDisplay";

    import SimplifiedButton from "./SimplifiedButton.vue";

    import { submitUserReport, checkForExistingReport, createUserReportRecord } from "@/requests/LocationRequests.js";

    export default {
        name: "NearbyContainer",
        props: ["usingGeolocation", "currentPosition", "showMapButton", "isHorizontal", "getOwnData", "pageType"],
        data() {
            return {
                isReportMode: false,
                reportPage: 1,
                reportSelectedRoute: null,
                reportSelectedID: null,
                reportSelectedName: null,
                reportSelectedType: null
            }
        },
        components: {
            NearbyRoutes,
            NearbyStops,
            NearbyRouteDisplay,
            SimplifiedButton
            // MapButton
        },
        watch: { 
            isHorizontal: function(newVal, oldVal) {
                console.log('HORIZONTAL changed: ', newVal, ' | was: ', oldVal)
            }
        },
        methods: {
            navigateToStops(trip){
                if(this.isReportMode){
                    console.log("SELECTED TRIP", trip);
                    this.reportPage = 2;
                    this.reportSelectedRoute = trip.VehicleRoute;
                    this.reportSelectedID = trip.VehicleID;
                    this.reportSelectedName = trip.VehicleID;
                    this.reportSelectedType = "train";
                    return;
                }
                this.$emit("navigate-to-stops", trip);
            },
            navigateToTrips(stop, route, direction, stopName, mode){
                if(this.isReportMode){
                    console.log("SELECTED STOP", stopName, (stop.ID || stop));
                    this.reportPage = 2;
                    this.reportSelectedID = stop.ID || stop;
                    this.reportSelectedName = stopName;
                    this.reportSelectedType = "station";

                    return;
                }
                this.$emit("navigate-to-trips", stop, route, direction, stopName, mode);
            } ,
            startReporting(){
                this.isReportMode = !this.isReportMode;
                this.reportSelectedID = null;
                this.reportSelectedName = null;
                this.reportSelectedType = null;
            },
            closeModal(){
                this.isReportMode = false;
                this.reportSelectedID = null;
                this.reportSelectedName = null;
                this.reportSelectedType = null;
                this.reportPage = 1;
            },
            reportGoBack() {
                this.reportPage = 1;
            },
            submitReport(reportType) {
                const params = {
                    text: reportType,
                    type: this.reportSelectedType,
                    id: this.reportSelectedID,
                    mode: "RAIL"
                };

                console.log("REPORTING", params);

                if(!checkForExistingReport(params)){
                    submitUserReport(params)
                    .then(({data}) => {
                        console.log("REPORT SUBMISSION DONE", data);
                        createUserReportRecord(params);
                    });
                }

                this.reportSelectedID = null;
                this.reportSelectedName = null;
                this.reportSelectedType = null;

                this.reportPage = 3;
            }
        }
    }
</script>

<style scoped>
    .mobile .nearby-inner .v-btn {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
    
    .reporting-shift {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
</style>

<style>
    .wrapper {
        /* margin-top: 12vh; what was this about? */
        padding-right: 6vw;
        text-align: center;
        font-size: 1.1rem;
    }

    .wrapper.geo-disabled {
        padding-top: calc(30 * var(--mvh));
    }

    .mobile .wrapper{
        margin-top: 0;
        margin-bottom: calc(1 * var(--mvh));
        padding-right: 0;
        position: relative;
    }

    .mobile .map-container .wrapper {
        margin-top: calc(4 * var(--mvh));
    }

    .mobile .wrapper.geo-disabled {
        padding-top: initial;
    }

    .horizontal.wrapper {
        height: calc(25 * var(--mvh));
    }

    .horizontal .nearby-container {
        height: 100%;
    }

    .landscape.nearby-container {
        position: absolute;
    } 

    .map-container .landscape.nearby-container {
        top: calc(74 * var(--mvh));
    }

    .nearby-inner {
        /* margin: 0vh 2vw 4vh 0; */
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        margin-top: calc(12 * var(--mvh));

        padding: 2px;
    }

    .lightrail .nearby-inner {
        margin-top: calc(22 * var(--mvh));
    }

    .horizontal .nearby-inner {
        margin-top: 0;
        justify-content: center;
    }

    .mobile .nearby-inner {
        margin-top: 0;
    }

    .nearby-message {
        margin-bottom: calc(4 * var(--mvh));
        /* margin-right: 3vw; */
        color: var(--primary-color);
        letter-spacing: 0.1vw;
        position: relative;
        align-self: center;
        font-size: 2rem;
    }

    .mobile .nearby-message {
        font-size: 1.7rem;
    }

    /* .nearby-message::before {
        --line-height: 0.5vh;
        content: "";
        position: absolute;
        top: 50%;
        left: -7vw;
        height: var(--line-height);
        width: 5vw;
        background-color: #1867c0;
    } */

    .icon-test {
        margin-bottom: calc(4 * var(--mvh));
        /* margin-left: 1vw; */
        background: url("../assets/bus_icon_blue.svg");
        background-color: rgba(0,0,0,0.2);
        height: calc(6 * var(--mvh));
        width: calc(6 * var(--mvh));
        position: relative;
    }


    .report-mode {
        background-color: #fde1e1; 
        border-radius: 1rem;
        position: relative;

        
    }

    .mobile .report-mode {
        width: 100vw;
    }

    .selection-message {
        font-size: 1.5rem;
        margin: 1rem 0;
        background-color: white;
        border: 1px solid black;

        border-radius: 0.5rem;
        padding: 0.5rem;
        margin: 0 0.25rem;

        animation: flash 0.5s linear forwards;
    }

    @keyframes flash {
        0% {
            background-color: white;
            box-shadow: 0 0 0 rgba(0,0,0,0);
            transform: scaleY(0);
        }
        50% {
            background-color: gold;
            box-shadow: 0 0 2rem rgba(255,215,0,0.3);
            transform: scaleY(1);
        }
        100% {
            background-color: white;
            box-shadow: 0 0 0 rgba(0,0,0,0);
            transform: scaleY(1);
        }
    }

    .report-modal {
        position: fixed;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        box-shadow: 0 0 5rem rgba(0, 0, 0, .4);
        background-color: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        padding: 4.5rem 4rem 5.5rem;
        animation: modal-open .2s linear forwards;
        min-height: 400px;
        z-index: 1000;
    }

    .mobile .report-modal {
        top: 50%;
        left: 50%;
        width: 80%;
        padding: 4.5rem 1rem 5.5rem;
    }

    @keyframes modal-open {
        0% {
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .modal-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-route-display {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(1.2);
        margin-bottom: 0.5rem;
    }

    .report-modal .modal-route-display {
        transform: scale(1.4);
    }

    .report-button,
    .cancel-button {
        background: black;
        color: white;
        border-radius: 1.2rem;
        padding: 0.45rem 1rem;
        font-weight: bold;
        font-size: 1.3rem;
        text-transform: uppercase;
    }

    .cancel-button,
    .report-button {
        margin: 1rem 0 1.5rem;
        font-size: 1rem;
    }

    .mobile .cancel-button,
    .mobile .report-button {
        font-size: 0.9rem;
    }

    .modal-button {
        color: black;
        border-radius: 2rem;
        padding: .5rem 1rem;
        font-weight: 500;
        font-size: 1.3rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .police-button {
        background-color: #ededfe;
    }

    .crowd-button {
        background-color: #fbe2e2;
    }

    .delay-button {
        background-color: #fcf7ca;
    }

    .report-icon {
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        /* border-radius: 50%; */
        /* border: 1px solid rgb(170, 170, 170); */

        background-size: cover;
        background-position: center;
    }

    .modal-cancel {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.65rem 0.8rem;
        font-size: 1.1rem;
        color: white;
        background-color: black;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-cancel:hover {
        background-color: #222;
    }
    
    .report-back-button {
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        font-size: 1rem;
        /* color: #444; */
        background: var(--primary-color);
        color: white;
        padding: 0.2rem 0.5rem 0.15rem;
        border-radius: 1rem;
    }

    .report-back-button-icon {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        height: 2.2rem;
        width: 2.2rem;
        background: url("../assets/back-arrow.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .report-back-button:hover {
        opacity: 0.9;
    }

    
    .modal-button:hover {
        opacity: 0.9;
    }

    .report-modal h3 {
        font-size: 2rem;
        padding: 0 0 2rem;
        font-weight: 500;
    }

    .report-modal-second-layout {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        gap: 3rem;
    }

    .mobile .report-modal-second-layout {
        display: flex;
        flex-direction: column;
    }

    .report-modal-issue-list {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .police-icon {
        background-image: url("~@/assets/Police_icon.png");
    }

    .crowd-icon {
        background-image: url("~@/assets/Crowd_icon.png");
    }

    .delay-icon {
        background-image: url("~@/assets/Delay_icon.png");
    }

    .report-modal .v-btn {
        padding: 0 16px;
    }

    .page-counter {
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: .75rem;
        padding: .6rem 0.75rem;
        border-radius: 1rem;
        width: fit-content;
        background-color: #f2f2f2;
        margin-top: 0.7rem;
        margin-left: auto;
        margin-right: auto;
    }

    .page-circle {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        background-color: #aaa;
    }

    .circle-active {
        background-color: #444;
    }

    .mobile-cover {
        position: fixed;
        top: -100vh;
        left: -100vw;
        width: 200vw;
        height: 200vh;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1;
        pointer-events: none;
    }

    /* @media only screen and (max-width: 1050px) {
        .nearby {
            width: 90%;
            margin: 0 0 4vh 0;
        }
    }

    .nearby.mobile {
        align-items: center;
    } */

    /* @media only screen and (min-width: 601px) and (max-width: 1050px) {
        .nearby {
            align-items: center;
        }
    } */
</style>