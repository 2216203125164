<!-- 
        <v-btn
        class="mx-2"
        fab
        light

        @click="gotoStops"
    >
        {{routeName}}
    </v-btn> -->

<template>
    <button
        v-if="pageType === 'rail'"
        @click="gotoStops"
        class="button"
        :style="
            `position: relative; 
            width: ${$isMobile ? '18vw' : ($isTablet ? 'clamp(5rem, 5vw, 10rem)' : 'clamp(3rem, 5vw, 10rem)')}; 
            height: ${$isMobile ? '18vw' : ($isTablet ? 'clamp(5rem, 5vw, 10rem)' : 'clamp(3rem, 5vw, 10rem)')};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #eee;
            `
        "
    >
        <span :style="`font-size: ${$isTabletForm ? 1.8 : 1.8}rem; background-color: rgba(255, 255, 255, 0.4); font-weight: bold; opacity: ${$isTabletForm ? 0.8 : 0.7};`">{{remove0(vehicleID)}}</span>
        <div :style="
            `width: 100%; height: 100%; opacity: 0.45; z-index: -1;
            position: absolute;
            background-image: url(./line_icons/${routeName}_icon.png); background-size: contain; background-position: center;
            `
        "></div>
        <!-- filter: drop-shadow(0px 0px 10px black); -->
    </button>
    <SimplifiedButton
    v-else
        :light="true"
        :style="'border-radius: 50%; height: 4rem; width: 4rem;'"
        @click-event="gotoStops"
    >
        {{ routeName }}
    </SimplifiedButton>
</template>

<script>

    import { removeLead0 } from "@/Utilities";

    import SimplifiedButton from "@/components/SimplifiedButton.vue";

    export default {
        name: "NearbyRouteDisplay",
        props: ["routeName", "vehicleID", "index"],
        components: {
            SimplifiedButton
        },
        data() {
            return {
                pageType: "",
            };
        },
        created() {
            this.pageType = this.$route.path.split("/")[1];
        },
        methods: {
            remove0(val){
                return removeLead0(val);
            },
            gotoStops(){
                console.log("MEMBER GOTO STOPS", this.$props.index);
                // show the stops for this route, right?
                this.$emit("navigate-to-stops", this.$props.index);
            }
        }
    }
</script>