<template>
    <div :class="'simplified button ' + fullClass" @click="clickEvent"
        :style="style"
        :disabled="disabled"
        ref="button"
    >
        {{ buttonText }}
        <slot />
    </div>
</template>

<script>
    export default {
        name: "SimplifiedButton",
        props: ["buttonText", "buttonClass", "light", "dark", "rounded", "disabled", "color", "background", "border", "borderColor", "noHover"],
        components: {
        },
        data() {
            return {
                style: "",
                fullClass: ""
            }
        },
        watch: {
            buttonText() {this.buildStyle();},
            buttonClass() {this.buildStyle();},
            light() {this.buildStyle();},
            dark() {this.buildStyle();},
            rounded() {this.buildStyle();},
            disabled() {this.buildStyle();},
            color() {this.buildStyle();},
            background() {this.buildStyle();},
            border() {this.buildStyle();},
            borderColor() {this.buildStyle();}
        },
        created() {
            this.buildStyle();
        },
        methods: {
            clickEvent() {
                setTimeout(() => {
                    this.$emit("click-event");
                }, 100);
            },
            buildStyle(){
                const props = this.$props;

                const light = props.light || (!props.light && !props.dark);
                console.log("BUTTON PROPS", props, props.disabled, light, this.$refs);

                let styleBuilder = "";
                let classBuilder = props.buttonClass;

                if(light){
                    classBuilder += " light-button";
                    if(!props.noHover){
                        classBuilder += " lighthover";
                    }
                }

                if(props.dark) {
                    classBuilder += " dark-button";
                    if(!props.noHover){
                        classBuilder += " darkhover";
                    }
                }

                if(props.rounded) {
                    styleBuilder += " border-radius: 2rem; ";
                }

                if(props.disabled){
                    styleBuilder += " background-color: #ccc; color: rgba(0, 0, 0, 0.26); ";
                }

                if(props.color){
                    styleBuilder += ` color: ${props.color}; `;
                }

                if(props.background){
                    styleBuilder += ` background-color: ${props.background}; `;
                }

                if(props.border){
                    styleBuilder += ` border: ${props.border}; `;
                }

                if(props.borderColor) {
                    styleBuilder += ` border-color: ${props.borderColor}; `;
                }

                this.style = styleBuilder;
                this.fullClass = classBuilder;

                if(!props.noHover){
                    this.createClickAnimation();
                }
            },
            createClickAnimation(){
                const refs = this.$refs;

                setTimeout(() => {
                    if(refs.button){
                        const expand = (evt) => {
                            // create a circle overlayed onto the button,
                            // animate it expanding 
                            const circle = document.createElement("div");
                            circle.classList.add("click-circle");

                            const x = evt.clientX;
                            const y = evt.clientY;

                            const rect = refs.button.getBoundingClientRect();

                            circle.style.top = (y - rect.top) + "px";
                            circle.style.left = (x - rect.left) + "px";

                            refs.button.appendChild(circle);

                            setTimeout(() => {
                                circle.remove();
                            }, 100);
                        }

                        refs.button.addEventListener("click", expand);
                    }
                    else{
                        console.log("BUTTON PROPS NO BUTTON?");
                    }
                }, 20);
            }
        }
    }   
</script>

<style>
    .click-circle {
        position:absolute;
        transform-origin: center;
        z-index: 2;
        height: 1px;
        width: 1px;
        border-radius: 50%;
        animation: expand 0.1s linear forwards;
    }

    .light-button .click-circle {
        background-color: #777;
    }

    .dark-button .click-circle {
        background-color: white;
    }

    @keyframes expand {
        0% {
            opacity: 0.5;
            height: 1px;
            width: 1px;
        }
        100%{
            opacity: 0.1;
            transform: scale(500);
        }
    }
</style>

<style scoped>
    .button {
        align-items: center;
        border-radius: 0.25rem;
        display: inline-flex;
        flex: 0 0 auto;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
        justify-content: center;
        outline: 0;
        position: relative;
        text-decoration: none;
        text-indent: 0.0892857143em;
        text-transform: uppercase;
        transition-duration: 0.28s;
        transition-property: box-shadow, transform, opacity, background-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        overflow: hidden;

        cursor: pointer;
        /* font-size: 0.875rem; */

        border: 1px solid var(--primary-color);

        height: 2.5rem;
        min-width: 4rem;
        padding: 0 1rem;
        min-height: 36px;
        /* max-width: 28vw; */
    }

    .light-button {
        background-color: #f5f5f5; 
        color: var(--primary-color);
    }

    .dark-button {
        background-color: var(--primary-color); 
        color: white;
    }

    .lighthover:hover {
        background-color: #e5e5e5;
    }

    .darkhover:hover {
        opacity: 0.85;
    }

</style>