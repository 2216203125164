var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"appParent",staticStyle:{"height":"100vh","overflow":"hidden"},attrs:{"id":"app-parent-container"}},[(/*pageType === 'rail' && */ (_vm.$isMobile || _vm.$isTabletForm) && _vm.query.layout !== 's')?_c('Chat',{attrs:{"mobile":true,"tablet":_vm.$isTabletForm && !_vm.$isMobile,"isRotated":_vm.$isRotatedMobile,"largeTablet":_vm.$isLargeTablet}}):_vm._e(),_c('div',{ref:"app",class:'main' 
          + (_vm.$isMobile ? ' mobile' : '') 
          + (_vm.$isTablet ? ' tablet' : '')
          // + (($isMobile && pageType && pageType !== 'rail') ? ' scroll-container-mobile' : '') 
          + ((_vm.$isTabletForm /*|| query.hidemodeswitch || $isSignMode*/) ? ' smallscreen' : '') 
          + ((_vm.$isSignMode || _vm.query.hidemodeswitch) ? ' hidemodeswitch' : '')
          + (_vm.$isSignMode ? ' sign' : '')
          + (_vm.$isRotatedMobile ? ' full-rotate' : '')
          + ((_vm.query.dark !== false) ? ' dark' : '')
          + ((_vm.$isTabletForm) ? '' : ' landscape-container')
          + ((_vm.$isVeryLargeScreen) ? ' very-large-screen' : '')
          + (_vm.query.inStation ? ' in-station' : '')
          + ((_vm.$route.fullPath.indexOf('trips') > 0) ? ' trip-page' : ''),attrs:{"id":"app"}},[(/*pageType === 'rail' &&*/ !_vm.$isMobile && !_vm.$isTabletForm && _vm.query.layout !== 's')?_c('Chat',{attrs:{"largeTablet":_vm.$isLargeTablet}}):_vm._e(),(_vm.pageType !== '' && !_vm.$isSignMode)?_c('div',{class:'left-spacer ' + _vm.pageType}):_vm._e(),_c('router-view',{key:_vm.$route.fullPath}),(_vm.$isTabletForm && _vm.pageType !== '' && _vm.$data.query.footer === false)?_c('div',{class:'mobile-nav' + (_vm.showNav ? ' slide-in' : ' slide-out')},[_c('NavigationButtons',{attrs:{"showNav":_vm.showNav,"pageType":_vm.pageType,"query":_vm.query},on:{"onComplete":_vm.toggleNav}}),_c('button',{staticClass:"mobile-nav-button",on:{"click":_vm.toggleNav}},[_vm._v("X")])],1):_vm._e(),(_vm.$isTabletForm && !(_vm.query.hidemodeswitch || _vm.$isSignMode) && _vm.pageType !== '' && _vm.$data.query.footer === false)?_c('button',{class:'mobile-nav-button' + (_vm.showNav ? ' slide-out' : ' slide-in'),on:{"click":_vm.toggleNav}},[_vm._v(" MENU ")]):_vm._e(),(false && _vm.$isDisconnected)?_c('div',{staticStyle:{"position":"absolute","bottom":"0","right":"0","z-index":"100","height":"20vh","width":"20vh","background-color":"red","color":"white"}},[_vm._v(" Network Disconnection - too many consecutive timeouts ")]):_vm._e()],1),(_vm.$isTabletForm && _vm.pageType !== '' && _vm.$data.query.footer !== false)?_c('div',{ref:"tripFooter",class:'footer' + (_vm.$isRotatedMobile ? ' rotated' : '')},[_c('NavigationButtons',{attrs:{"showNav":true,"pageType":_vm.pageType,"query":_vm.query}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }