<template>
    <div class="dropdown-container" style="transition: 0.3s;">
        <div class="parent" ref="parent" @click="toggleShow">
            <div :class="'content' + (totalLength > 37 ? ' left': '')">
                <span v-if="items && items.length > 0 && hasValue && !show">
                    <span style="text-transform:none">
                        {{ prefix }}
                    </span>
                    {{ displayValue }}
                </span>
                <span v-else-if="wait">
                    Waiting for Data
                </span>
                <span v-else-if="(false && $isMobile) || (items && items.length > 0 && !show)">
                    {{ displayValue }}
                </span>
                <input type="text" :class="(!(items && items.length > 0) || (items && items.length > 0 && !show) || (wait)) ? 'hidden-behind' : 'show'" ref="input" />
            </div>
        </div>
        <div class="show-container">
            <div :class="className" ref="positioned">
                <div
                    v-for="(item, index) in currentItems"
                    :key="index"
                    :class="(valueField ? item[valueField] : item) === value ? 'base selected': 'base'"
                    :data-value="valueField ? item[valueField] : item" 
                    @click="selectValue"
                >
                    {{ displayField ? item[displayField] : item }}
                </div>
            </div>
        </div>
    </div>
</template>

    @onChange="setDirection"

<script>
    export default {
        name: "DropDown",
        props: [
            "defaultValue",
            "waiting",
            "prefix",
            "value",
            "disabled",
            "valueField",
            "displayField",
            "items",
            "leftJustify",
            "shortList"
        ],
        data() {
            return {
                displayValue: "",
                hasValue: false,
                activiated: false,
                show: false,
                className: "positioned",
                listener: null,
                textListener: null,
                totalLength: 0,
                currentItems: [],
                wait: true,
                selectedIndex: -1
            };
        },
        created() {
            console.log("WE HAVE DROPDOWN VALUE?", this.$props.items);
            this.setValue(this.$props.value);
            this.totalLength = (this.prefix ? this.prefix.length : 0) + (this.displayValue ? this.displayValue.length : 0);
            this.currentItems = this.$props.items; 
            this.wait = this.$props.waiting;

            const containerRef = document.querySelector(".dropdown-container");
            containerRef.style.borderRight = "2px solid #888";

            const str = JSON.stringify(this.$props.items);
            const hidden = document.createElement("div");
            hidden.innerText = str;
            hidden.style.width = "0px";
            hidden.style.height = "0px";
            hidden.style.overflow = "hidden";

            containerRef.appendChild(hidden);

            console.log("container created", containerRef);

            console.log("CREATED WAITING", this.$props.waiting);
        },
        destroyed() {
            this.removeListener();
            this.removeTextListener();
        },
        watch: {
            value: function(newVal, oldVal) {
                console.log("change dropdown VALUE", newVal, oldVal);

                const changeValue = () => {
                    if(newVal !== oldVal){
                        console.log("CHANGE DROPDOWN", newVal);
                        if(this.$props.items.length < 1 && newVal != -1){
                            console.log("NO DROPDOWN ITEMS YET", this.$props.items);
                            setTimeout(changeValue, 50);
                        }
                        else{
                            this.setValue(newVal);
                        }
                    }
                }

                changeValue();
            },
            items: function(newItems) {
                this.currentItems = newItems;

                const containerRef = document.querySelector(".dropdown-container");
                containerRef.style.borderLeft = "2px solid #888";

                const str = JSON.stringify(newItems);
                const hidden = document.createElement("div");
                hidden.innerText = str;
                hidden.style.width = "0px";
                hidden.style.height = "0px";
                hidden.style.overflow = "hidden";

                containerRef.appendChild(hidden);

                console.log("container items changed", containerRef);
            },
            waiting: function(wait) {
                this.wait = wait;
                
                const indicate = document.createElement("div");
                
                indicate.classList.add("message-square");
                if(!this.$isTroubleshootingMode){
                    indicate.classList.add("message-hidden");
                }

                indicate.style.height = "50px";
                indicate.style.width = "50px";
                indicate.style.backgroundColor = "yellow";
                indicate.style.zIndex = "10000";
                indicate.style.position = "absolute";
                indicate.style.top = 0;
                indicate.style.left = "400px";

                indicate.textContent = "waiting? " + wait;

                document.body.appendChild(indicate);
            }
        },
        methods: {
            focusAndOpenKeyboard(el, timeout) {
                if(!timeout) {
                    timeout = 50;
                }
                if(el) {
                    // Align temp input element approximately where the input element is
                    // so the cursor doesn't jump around
                    var __tempEl__ = document.createElement('input');
                    __tempEl__.style.position = 'absolute';
                    __tempEl__.style.top = (el.offsetTop + 7) + 'px';
                    __tempEl__.style.left = el.offsetLeft + 'px';
                    __tempEl__.style.height = 0;
                    __tempEl__.style.opacity = 0;
                    // Put this temp element as a child of the page <body> and focus on it
                    document.body.appendChild(__tempEl__);
                    __tempEl__.focus();

                    // The keyboard is open. Now do a delayed focus on the target element
                    setTimeout(function() {
                    el.focus();
                    el.click();
                    // Remove the temp element
                    document.body.removeChild(__tempEl__);
                    }, timeout);
                }
                },
            resetMainContainerPosition(){
                const mainContainer = this.$refs.parent.parentElement;
                mainContainer.style.transform = "";
                mainContainer.style.zIndex = "";
            },
            toggleShow(evt) {

                if(evt && evt.target === this.$refs.input){
                    return;
                }

                if(!this.$props.items || this.$props.items.length < 1){
                    return;
                }

                this.show = !this.show;
                this.activated = true;

                this.className = "positioned" + (this.show ? ' show' : ' hide')

                if(this.show){

                    console.log("SHOWING", this.$isMobile, this.$refs);

                    const windowHeight = window.innerHeight;
                    const height = windowHeight* 0.3;
                    const rect = this.$refs.parent.getBoundingClientRect();
                    const diff = windowHeight - rect.bottom - height;
                    console.log(diff, rect.bottom, windowHeight);

                    const mainContainer = this.$refs.parent.parentElement;

                    this.setListener();
                    this.setTextListener();


                    
                    setTimeout(() => {
                        console.log(this.$refs.positioned);
                        if(diff < 0) {
                            this.$refs.positioned.style.top = (diff - 10) + "px";
                        }
                            this.$refs.positioned.style.display = "initial";
                        // setTimeout(() => {
                        //     this.$refs.positioned.style.display = "initial";
                        // }, 0);

                        if(this.$isMobile){
                            // if on mobile, reposition the textbox
                            mainContainer.style.zIndex = 100;
                            this.createBackground(mainContainer);

                            const gap = this.$props.shortList ? 330 : 420;
                            const movement = diff - gap;
                            console.log("MOVEMENT", movement-rect.top, rect.top);
                            if(movement < 0){
                                mainContainer.style.transform = `translateY(${movement}px)`;
                            }


                        }
                    }, 0);

                    this.setScrollPosition();
                }
                else{
                    this.removeTextListener();
                    this.removeListener();
                    this.resetMainContainerPosition();
                    this.hideBackground();
                }

                if(evt) {
                    const circle = document.createElement("div");
                    circle.classList.add("circle");

                    const rect = this.$refs.parent.getBoundingClientRect();

                    circle.style.left = evt.clientX - rect.left + "px";
                    circle.style.top = evt.clientY - rect.top + "px";

                    // document.appendChild(circle);
                    this.$refs.parent.appendChild(circle);

                    setTimeout(() => {
                        circle.remove();
                    }, 1000);
                }
            },
            getSelectedItem(val) {
                console.log("DROPDOWN ITEMS", this.items);
                if(val){
                    return this.items.find((i) => {
                        const comp = this.$props.valueField ? i[this.$props.valueField] : i;
                        console.log("DROPDOWN INNER COMPARISON", val, i, comp)
                        return comp == val || comp.toLowerCase() == val.toLowerCase();
                    });
                }
            },
            setValue(val) {
                // find the list item corresponding th=o the value being set
                const selectedItem = this.getSelectedItem(val);

                // if it exists, then set the display value based on it
                if(selectedItem){
                    console.log("FOUND DROPDOWN ITEM", selectedItem);
                    this.displayValue = this.displayField ? selectedItem[this.displayField] : selectedItem;
                    this.hasValue = true;
                }
                else{
                    console.log("DROPDOWN ITEM NOT FOUND");
                    this.displayValue = this.$props.defaultValue;
                    this.hasValue = false;
                }

                this.displayValue = this.displayValue.replace("&amp;", "&");
                this.totalLength = (this.prefix ? this.prefix.length : 0) + (this.displayValue ? this.displayValue.length : 0);
            },
            selectValue(evt, value = null){
                const val = value || evt.target.dataset.value;
                this.setValue(val);
                console.log("SELECTED VALUE", val);
                this.$emit('onChange', val);
            },
            setListener(){
                let set = false;
                const func = (evt) => {
                    console.log("DROPDOWN CLICK LISTENER", evt.target, this.$refs.input);
                    if(set && evt.target !== this.$refs.parent && evt.target !== this.$refs.input && evt.target !== this.$refs.positioned){
                        this.toggleShow();
                    }
                    set = true;
                }
                this.listener = func;
                window.addEventListener("click", func);
            },
            removeListener(){
                if(this.listener){
                    window.removeEventListener("click", this.listener);
                }
                this.listener = null;
            },
            setTextListener(){
                console.log("SET TEXT LISTENER?");

                let filter = "";
                let textRef = null;

                const mobile = this.$isMobile;

                let lastMobileValue = "";

                const func = (evt) => {
                    console.log("KEY DOWN", evt.key, mobile);

                    if(mobile){
                        console.log("MOBILE EVENT LISTENER", evt.target);
                        setTimeout(() => {
                            // look at the input field direction
                            this.filterCurrentItems(evt.target.value);
                            // this.getSelectedIndex();
                            this.setScrollPosition();

                            if(evt.target.value === lastMobileValue){
                                if(this.currentItems.length === 1){
                                    // select this item
                                    // hmmm, how would I do this?
                                    const field = this.$props.valueField;
                                    const item = this.currentItems[0];
                                    const value = field ? item[field] : item;
                                    this.selectValue(null, value);
                                    this.toggleShow();
                                }
                            }
                            else{
                                lastMobileValue = evt.target.value;
                            }
                        }, 10);
                        return;
                    }

                    evt.preventDefault();

                    if(textRef){
                        // check for special keys
                        // backspace
                        // enter

                        if(evt.keyCode === 8 && filter.length > 0){
                            filter = filter.substr(0, filter.length-1);
                            textRef.value = filter;
                            this.filterCurrentItems(filter);
                            // this.getSelectedIndex();
                            this.setScrollPosition();
                        }
                        else if(evt.keyCode === 13 && this.currentItems.length === 1){
                            // select this item
                            // hmmm, how would I do this?
                            const field = this.$props.valueField;
                            const item = this.currentItems[0];
                            const value = field ? item[field] : item;
                            this.selectValue(null, value);
                            this.toggleShow();
                        }
                        else if(evt.keyCode === 13) {
                            this.toggleShow();
                        }
                        else if(this.isPrintable(evt)){
                            // then only take printable characters
                            filter += evt.key;
                            textRef.value = filter;
    
                            this.filterCurrentItems(filter);
                            // this.getSelectedIndex();
                            this.setScrollPosition();
                        }
                        else if(evt.keyCode === 38){
                            // up arrow

                            // no selection or current selection at top of list
                            //   - do nothing

                            // selection
                            //   - move selected
                            //   - fill in text

                            if(this.selectedIndex > 0){
                                const newItem = this.currentItems[this.selectedIndex-1];
                                const newValue = this.$props.valueField ? newItem[this.$props.valueField] : newItem ;
                                this.selectedIndex = this.selectedIndex - 1;
                                this.selectValue(null, newValue);

                                setTimeout(() => {
                                    this.setScrollPosition();
                                }, 10);
                            }
                        }
                        else if(evt.keyCode === 40){
                            // down arrow

                            // current selection at bottom of list
                            //   - do nothing

                            if(this.selectedIndex < this.currentItems.length - 1){
                                // no selection
                                //   - set selected to first element
                                //   - fill in text

                                // selection
                                //   - move selected
                                //   - fill in text

                                const newItem = this.currentItems[this.selectedIndex + 1];
                                const newValue = this.$props.valueField ? newItem[this.$props.valueField] : newItem ;
                                this.selectedIndex = this.selectedIndex + 1;
                                this.selectValue(null, newValue);

                                setTimeout(() => {
                                    this.setScrollPosition();
                                }, 10);
                            }
                        }
                    }
                }

                // document.addEventListener("keydown", func);
                this.textListener = func;

                this.getSelectedIndex();
                textRef = this.$refs.input;

                console.log("THE TEXT REF", textRef);

                if(textRef){
                    textRef.placeholder = this.displayValue;
                    // textRef.focus();
                    // textRef.click();
                    this.focusAndOpenKeyboard(textRef);
                    textRef.addEventListener("keydown", func);
                }
                else{
                    alert("NO TEXT REFERENCE TO FOCUS ON");
                }

                setTimeout(() => {

                }, 0);
            },
            removeTextListener(){
                if(this.textListener && this.$refs.input){
                    this.$refs.input.removeEventListener("keydown", this.textListener)
                }
                this.textListener = null;
                this.currentItems = this.$props.items;
                this.selectedIndex = -1;

                const textRef = this.$refs.input;
                if(textRef){
                    textRef.value = "";
                }
            },
            createBackground(container){
                const background = document.createElement("div");
                background.classList.add("dropdown-background");
                container.appendChild(background);
            },
            hideBackground(){
                const background = document.querySelector(".dropdown-background");
                background.remove();
            },
            setScrollPosition() {
                this.getSelectedIndex();

                setTimeout(() => {
                    const count = this.currentItems.length;
                    const height = this.$refs.positioned.scrollHeight;
                    const index = this.selectedIndex;
                    const scroll = index * height/count;

                    console.log("SCROLL POSITION", index, count, height);

                    this.$refs.positioned.scrollTop = scroll;
                }, 10);
            },
            getSelectedIndex() {
                let index = -1;
                this.currentItems.forEach((item, i) => {
                    if(this.$props.valueField){
                        if(item[this.valueField] === this.$props.value){
                            index = i;
                        }
                    }
                    else{
                        if(item === this.$props.value){
                            index = i;
                        }
                    }

                    if(index > -1){
                        return;
                    }
                });

                this.selectedIndex = index;
            },
            filterCurrentItems(filter){
                const copy = this.$props.items.filter(val => {
                    const field = this.$props.displayField
                    const display = field ? val[field] : val;

                    if(display.toLowerCase().indexOf(filter.toLowerCase()) >= 0){
                        return true;
                    }

                    return false;
                });

                this.currentItems = [...copy];
            },
            isPrintable(evt){
                // https://stackoverflow.com/questions/12467240/determine-if-javascript-e-keycode-is-a-printable-non-control-character
                const keycode = evt.keyCode;

                const valid = 
                    (keycode > 47 && keycode < 58)   || // number keys
                    keycode == 32 /*|| keycode == 13*/   || // spacebar & return key(s) (if you want to allow carriage returns)
                    (keycode > 64 && keycode < 91)   || // letter keys
                    (keycode > 95 && keycode < 112)  || // numpad keys
                    (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                    (keycode > 218 && keycode < 223);   // [\]' (in order)

                return valid;
            }
        }
    }
</script>

<style scoped>

    .dropdown-container {
        position: relative;
    }
    .parent {
        /* width: 500px;
        position: absolute;
        top: 500px;
        left: 300px; */
        height: 2.5rem;
        min-width: 4rem;
        padding: 0 1rem;
        border: 1px solid var(--primary-color);
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;


        align-items: center;
        border-radius: 0.25rem;
        /* display: inline-flex; */
        flex: 0 0 auto;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
        justify-content: center;
        outline: 0;
        position: relative;
        text-decoration: none;
        text-indent: 0.0892857143em;
        text-transform: uppercase;
        transition-duration: 0.28s;
        transition-property: color, box-shadow, transform, opacity, background-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;

        background-color: white;
        z-index: 2;

        overflow: hidden;
    }

    .content {
        align-items: center;
        color: inherit;
        display: flex;
        flex: 1 0 auto;
        height: 100%;
        justify-content: inherit;
        line-height: normal;
        position: relative;
        transition: inherit;
        /* transition-property: opacity; */
    }

    .mobile .content {
        /* justify-content: initial; */
    }

    .content input {
        font-size: 1rem;
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .content input:focus {
        outline: none;
        /* background-color: rgb(241, 159, 159); */
    }

    .mobile .content.left * {
        /* justify-content: left; */
        /* word-wrap: break-word; */
        white-space: normal;
        text-align: center;
    }

    .parent:hover {
        background-color: #e9e9e9
    }

    .disabled { 
        color: #ccc;
        border: 1px solid #ccc;
    }

    .show-container {
        position: relative;
        height: 0;
        width: 100%;
        z-index: 3;
    }

    .positioned {
        position: absolute;
        height: 0;
        top: 1px;
        width: 100%;
        /* left: -17px; */
        /* width: calc(100% + 34px); */
        transition: 0.3s ease-out;
        overflow-y: auto;

        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        border-radius: 4px;
    }

    .show {
        height: initial;
        max-height: calc(30 * var(--mvh));
        opacity: 1;
    }

    .show .base {
        font-weight: 500;
        letter-spacing: 0.7px;
    }

    .hide {
        background: green;
        height: 0;
        opacity: 0;
    }

    .base {
        background: white;
        padding: 1rem;
        font-size: 1rem;
        font-weight: normal;
        transition: inherit;
        cursor: pointer;
    }

    .base:hover {
        background: #e9e9e9;
        /* color: red; */
        color: var(--primary-color);
    }

    .selected {
        /* background: #e9e9e9; */
        background-color: var(--primary-color);
        color: white;
        font-weight: inherit;
        text-transform: uppercase;
    }

    .selected::after{
        /* content: "*"; */
    }

    .hidden-behind {
        position: absolute;
        left: -5000px;
    }

</style>

<style>
    .circle {
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        z-index: 3;
        background-color: black;
        opacity: 0.3;

        animation: expand 0.3s ease-out forwards;
    }

    @keyframes expand {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(50);
            opacity: 0;
        }
    }
    
    .dropdown-background {
        position: fixed;
        top: -100vh;
        left: -100vw;
        width: 200vw;
        height: 200vh;
        background-color: rgba(0,0,0,0.25);
        z-index: 1;
    }
</style>